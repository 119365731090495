
import { computed, onMounted, ref } from "vue";
import DetailsRow from "@/components/DetailsRow.vue";
import DetailsHeader from "@/components/DetailsHeader.vue";
import { Student, WF_NationalIdentityNumberChange } from "@/interfaces/api";
import StudentService from "@/services/student-service";
import IdHelper from "@/helpers/id-helper";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";

export default {
  name: "Student",
  components: {
    DetailsRow,
    DetailsHeader,
  },
  setup(): Record<string, unknown> {
    const { t } = useI18n();
    const store = useStore();
    const show = false;
    const route = useRoute();
    const router = useRouter();
    const person = ref<Student | undefined>(undefined);
    const isSubmitted = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const year = ref<string | undefined>("");
    const month = ref<string>("");
    const day = ref<string>("");
    const nnnn = ref<string>("");
    const feedbackMessage = ref<string>("");
    const isSuccess = ref<boolean>(false);
    const roles = ref<string[]>([]);
    const startDate = ref<string | null>(null);
    const endDate = ref<string | null>(null);
    const dateFormat = ref<string>("yyyy-MM-dd");
    const updateInfo = ref<WF_NationalIdentityNumberChange | null>(null);
    const today = dayjs().format("YYYY-MM-DD");

    const years = ref(
      Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i)
    );
    const months = ref(
      Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"))
    );
    const days = ref(
      Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, "0"))
    );

    const validateNnnn = (event: Event) => {
      const target = event.target as HTMLInputElement;
      target.value = target.value.replace(/\D/g, "").slice(0, 4);
      nnnn.value = target.value;
    };

    const isFormValid = computed(
      () => year.value && month.value && day.value && nnnn.value.length === 4
    );

    const feedbackMessageClass = computed(() =>
      isSuccess.value ? "text-success" : "text-danger"
    );

    const submitUpdate = async () => {
      if (!person.value || !person.value.personalId) {
        console.error("Person value or personalId is undefined");
        return;
      }

      const newPersonalId = `${year.value}${month.value}${day.value}${nnnn.value}`;

      try {
        isLoading.value = true;

        const studentData: WF_NationalIdentityNumberChange = {
          luEduPersonPrimaryId: person.value.luEduPersonPrimaryId,
          newNationalIdentityNumber: newPersonalId,
        };

        await StudentService.submitStudentData(studentData);

        const msg =
          "Thank you for your request. " +
          " You will receive an email when your Personal Identity number has been updated in the Study Administration Systems";

        feedbackMessage.value = msg;
        isSuccess.value = true;
        isSubmitted.value = true;
      } catch (error) {
        feedbackMessage.value = "Något gick fel...";
        isSuccess.value = false;
        resetFields();
      } finally {
        isLoading.value = false;
      }
    };

    const cancelUpdate = () => {
      resetFields();
      router.push("/");
    };

    const resetFields = () => {
      if (!person.value || !person.value.personalId) {
        console.error("Person value or personalId is undefined");
        return;
      }

      year.value = person.value.personalId.slice(0, 4);
      month.value = "";
      day.value = "";
      nnnn.value = "";
    };

    const getStudent = async () => {
      const luEduPersonPrimaryId = IdHelper.getLuEduPersonPrimaryId(
        route.query["lucatid"] as string
      );

      try {
        const data = await StudentService.getStudentByLuEduPersonPrimaryId(
          luEduPersonPrimaryId
        );

        if (!data) {
          isSubmitted.value = false;
          return;
        }

        person.value = data;
        year.value = person.value.personalId.slice(0, 4);

        // Kontrollera om uppdatering finns
        try {
          const updateResponse =
            await StudentService.getIfUpdateIsInsertedByLuEduPersonPrimaryId(
              luEduPersonPrimaryId
            );

          if (updateResponse?.success && updateResponse.response) {
            isSubmitted.value = true;
            updateInfo.value = updateResponse.response;
            const newNationalIdentityNumber =
              updateResponse.response.newNationalIdentityNumber;
            year.value = newNationalIdentityNumber?.slice(0, 4) || "";
            month.value = newNationalIdentityNumber?.slice(4, 6) || "";
            day.value = newNationalIdentityNumber?.slice(6, 8) || "";
            nnnn.value = newNationalIdentityNumber?.slice(8, 12) || "";

            feedbackMessage.value =
              person.value.personalId === newNationalIdentityNumber
                ? "Your Personal Identity Number has been updated in the Study Administration Systems."
                : "You have submitted a request for an update of your Personal Identity Number.";

            isSuccess.value =
              person.value.personalId === newNationalIdentityNumber;
          } else {
            isSubmitted.value = false;
            console.log("No previous update found, inputs are active.");
          }
        } catch (error) {
          console.warn(
            "Update check returned 404, but inputs are still active."
          );
          isSubmitted.value = false;
        }

        return true;
      } catch (error: unknown) {
        console.error("Error in getStudent: ", error);
        feedbackMessage.value =
          "An error occurred while fetching student data.";
        isSubmitted.value = false;
      } finally {
        isLoading.value = false;
      }
    };

    const formatNationalIdentityNumber = (idNumber: string) => {
      if (!idNumber) {
        console.error("idNumber is undefined");
        return "";
      }
      return `${idNumber.slice(0, 8)}-${idNumber.slice(8)}`;
    };

    const hasInterimId = computed(
      () => person.value && /[A-Za-z]/.test(person.value.personalId)
    );

    const handleStartDateChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      startDate.value = input.value;
    };

    const handleEndDateChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      endDate.value = input.value;
    };

    const formattedStartDate = computed<string | null>({
      get() {
        return startDate.value;
      },
      set(value: string | null) {
        startDate.value = value;
      },
    });

    const formattedEndDate = computed({
      get() {
        return dayjs(endDate.value).format("YYYY-MM-DD");
      },
      set(value: string) {
        endDate.value = value;
      },
    });

    const areDatesValid = computed(() => {
      if (!startDate.value || !endDate.value) return true;
      return (
        dayjs(startDate.value).isBefore(endDate.value) ||
        dayjs(startDate.value).isSame(endDate.value)
      );
    });

    const areDatesFilled = computed(() => {
      return !!startDate.value && !!endDate.value;
    });

    onMounted(() => {
      getStudent();
      const currentUser = store.getters.getCurrentUser;
      roles.value = currentUser.entitlements;
    });

    return {
      t,
      formattedStartDate,
      formattedEndDate,
      handleStartDateChange,
      handleEndDateChange,
      show,
      person,
      years,
      isLoading,
      months,
      days,
      year,
      month,
      day,
      nnnn,
      updateInfo,
      feedbackMessage,
      feedbackMessageClass,
      cancelUpdate,
      submitUpdate,
      validateNnnn,
      getStudent,
      hasInterimId,
      isFormValid,
      isSubmitted,
      roles,
      startDate,
      endDate,
      dateFormat,
      formatNationalIdentityNumber,
      today,
      areDatesValid,
      areDatesFilled,
      resetFields,
    };
  },
};
